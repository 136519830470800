import React, { useMemo, useEffect } from 'react';
import FlatList from './FlatList';
import './DaysToggle.sass';
import { ReactComponent as ArrowRight } from '../static/img/arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../static/img/arrow-left.svg';
import HospitalLocationFilter from '../components/HospitalLocationFilter';

export default ({
  selectedDay,
  days,
  actions,
  showMonth,
  hospitals,
  selectedHospitals,
  setSelectedHospitals,
  setHideTimeSlots,
}) => {
  const month = useMemo(() => {
    const { date } = selectedDay || days[0].item;
    return date.format('MMMM');
  }, [selectedDay, days]);

  const daysForSelectedHospitals = useMemo(() => {
    let filteredDays = days.map(day => {
      if (day.item.clinic && selectedHospitals.includes(day.item.clinic.locations[0])) {
        return { ...day, disabled: false };
      } else if (day.item.clinic && !selectedHospitals.includes(day.item.clinic.locations[0])) {
        return { ...day, disabled: true };
      }
      return day;
    });
    filteredDays.filter(slot => slot.disabled === false).length > 0
      ? setHideTimeSlots(false)
      : setHideTimeSlots(true);
    return filteredDays;
  }, [days, selectedHospitals]);

  const updateSelectedDay = useEffect(() => {
    if (
      selectedDay &&
      selectedDay.clinic &&
      !selectedHospitals.includes(selectedDay.clinic.locations[0])
    ) {
      let nextValidDay = document.querySelector('.days-toggle__wrapper button:not(.m--disabled)');
      if (nextValidDay) nextValidDay.click();
    }
  }, [daysForSelectedHospitals]);

  return (
    <>
      {showMonth && (
        <div className="month-navigation__wrapper">
          {actions && (
            <button className="prev-month__btn" onClick={actions.prevMonth}>
              <ArrowLeft />
            </button>
          )}
          <div className="month-name">{month}</div>
          {actions && (
            <button className="next-month__btn" onClick={actions.nextMonth}>
              <ArrowRight />
            </button>
          )}
        </div>
      )}
      <HospitalLocationFilter
        hospitals={hospitals}
        selectedHospitals={selectedHospitals}
        setSelectedHospitals={setSelectedHospitals}
      />
      <div className="days-toggle__outer-wrapper">
        {actions && (
          <button className="prev-period__btn" onClick={actions.prev}>
            <ArrowLeft />
          </button>
        )}
        <div className="days-toggle__scroll">
          <div className="days-toggle__wrapper">
            <FlatList
              data={daysForSelectedHospitals}
              renderItem={({ item: { date }, disabled, selected, select }, index) => (
                <button
                  type="button"
                  className={`day-toggle__button ${selected && 'm--active'} ${disabled && 'm--disabled'}`}
                  key={index}
                  onClick={() => {
                    if (!disabled) select();
                  }}
                >
                  {date.format('ddd')}
                  <br />
                  {date.format('D MMM')}
                </button>
              )}
            />
          </div>
        </div>
        {actions && (
          <button className="next-period__btn" onClick={actions.next}>
            <ArrowRight />
          </button>
        )}
      </div>
    </>
  );
};
