import { v4 as uuid4 } from 'uuid';
import genQueryParams from '../utils/genQueryParams';

export default class {
  static getInstance(accessToken, refresh = async () => {}, logError = async () => {}) {
    if (this.instance === undefined) {
      this.instance = new this();
    }

    this.instance.logError = logError;
    this.instance.refresh = refresh;
    this.instance.token = accessToken;
    return this.instance;
  }

  constructor() {
    this.apimEnabled = global.BOOKING_ENGINE_VIA_APIM;
    this.apimSubscriptionKey = global.APIM_HA_SUBSCRIPTION_KEY;
    this.baseUrl = `${global.APIM_BASE_URL.slice(0, -'booking/'.length)}health-assessment/1.0/`;
  }

  async call(method, endpoint, { params = {}, body = undefined, headers = {} } = {}) {
    let response = null;
    const callWrapper = async token => {
      const url = `${this.baseUrl}${endpoint}${genQueryParams(params)}`;
      const init = {
        method,
        cache: 'no-store',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          ...headers,
        },
      };
      if (token) {
        init.headers.Authorization = `Bearer ${this.token}`;
      }

      if (this.apimEnabled) {
        init.headers['Ocp-Apim-Subscription-Key'] = this.apimSubscriptionKey;
        init.headers['X-Transaction-Id'] = uuid4();
      }

      if (['PUT', 'POST'].includes(method)) {
        init.body = body === undefined ? '' : JSON.stringify(body);
      }

      return fetch(url, init);
    };

    try {
      response = await callWrapper(this.token);
      if (response.status === 401 || response.status === 403) {
        const payload = await this.refresh();

        if (payload) {
          this.token = payload.access_token;
          response = await callWrapper(this.token);
        }
      }
      if (!response.ok) {
        const error = await response.json();
        throw error;
      }
    } catch (e) {
      this.logError({
        error: 'CUSTOM',
        text: 'Unfortunately, we can’t process your booking online due to an error. Please call us on 0300 123 1844 to complete your booking.',
      });
      throw e;
    }

    return response.json();
  }

  async getEligibility({ idaasId, payor }) {
    return this.call('GET', `GetEligibility/ha-packages/idaas-id/${idaasId}`, {
      params: {
        payor,
      },
    });
  }

  async getLocations(parameters) {
    return this.call('GET', `Appointment/locations`, {
      params: parameters,
    });
  }

  async getSlots(parameters) {
    return this.call('GET', `Appointment/slots`, {
      params: parameters,
    });
  }

  async postAppointment(body) {
    return this.call('POST', `Appointment/create`, {
      body,
    });
  }

  async putAppointment({ appointmentId, status }) {
    let extensions = [
      {
        url: 'http://fhir.lumeon.com/dstu2/appointment/email/confirmation',
        valueBoolean: true,
      },
    ];

    // Add waive cancellation charge FHIR extension if a cancelled appointment.
    if (status === 'cancelled') {
      extensions.push({
        url: 'http://fhir.lumeon.com/dstu2/appointment/waive-cancel-charges',
        valueBoolean: true,
      });
    }

    return this.call('PUT', `Appointment/${appointmentId}`, {
      body: {
        extension: extensions,
        resourceType: 'Appointment',
        id: appointmentId,
        status: status,
      },
    });
  }

  async getHaBookings({ idaasId, appointment_filter }) {
    return this.call('GET', `Appointment/vita/idaas-id/${idaasId}`, {
      params: {
        appointment_filter,
      },
    });
  }

  async getPatient(patientId) {
    return this.call('GET', `Patient/${patientId}`, {});
  }

  async putPatient({ patientId, mobilePhone }) {
    return this.call('PUT', `Patient/${patientId}`, {
      body: {
        resourceType: 'Patient',
        id: patientId,
        telecom: [
          {
            use: 'mobile',
            system: 'phone',
            value: mobilePhone,
          },
        ],
      },
    });
  }
}
