import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { Provider } from 'react-redux';
import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import moment from 'moment-timezone';
import GymClasses from './pages/GymClasses';
import MyBookings from './pages/MyBookings';
import reducers from './reducers';
import Beauty from './pages/Beauty';
import BeautyConfirm from './pages/BeautyConfirm';
import HMOT from './pages/HMOT';
import HMOTConfirm from './pages/HMOTConfirm';
import Hospital from './pages/Hospital';
import HospitalConfirm from './pages/HospitalConfirm';
import AuthCallback from './pages/AuthCallback';
import AuthWrapper from './components/AuthWrapper';
import GenericError from './components/GenericError';
import useDevtools from './hooks/useDevtools';
import Consultant from './pages/Consultant';
import ConsultantForm from './pages/ConsultantForm';
import ConsultantConfirm from './pages/ConsultantConfirm';
import HealthAssessmentDetails from './pages/HealthAssessmentDetails';
import HealthAssessmentTypes from './pages/HealthAssessmentTypes';
import HealthAssessmentLocations from './pages/HealthAssessmentLocations';
import HealthAssessmentSlots from './pages/HealthAssessmentSlots';
import HealthAssessmentConfirm from './pages/HealthAssessmentConfirm';
import HealthAssessmentConfirmed from './pages/HealthAssessmentConfirmed';
import HealthAssessmentBookings from './pages/HealthAssessmentBookings';
import { ParentWindowProvider, HealthAssessmentProvider } from './contexts';

moment.tz.setDefault('Europe/London');

const composeEnhancers = composeWithDevTools({});
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

const Devtools = () => {
  useDevtools();
  return null;
};

export default () => {
  return (
    <Provider store={store}>
      <ParentWindowProvider>
        <Devtools />
        <BrowserRouter>
          <GenericError />
          <Switch>
            <Route exact strict path="/auth/callback/">
              <AuthCallback />
            </Route>
            <Route>
              <AuthWrapper exclusions={[]}>
                <Switch>
                  <Route exact strict path="/">
                    <MyBookings />
                  </Route>
                  <Route exact strict path="/gyms/:gymId/gymclasses/">
                    <GymClasses />
                  </Route>
                  <Route exact strict path="/gyms/:gymId/beauty/">
                    <Beauty />
                  </Route>
                  <Route exact strict path="/gyms/:gymId/beauty/booked/">
                    <BeautyConfirm />
                  </Route>
                  <Route exact strict path="/gyms/:gymId/hmot/">
                    <HMOT />
                  </Route>
                  <Route exact strict path="/gyms/:gymId/hmot/booked/">
                    <HMOTConfirm />
                  </Route>
                  <Route exact strict path="/hospitals/:resourceId/events/:reservationId/">
                    <Hospital />
                  </Route>
                  <Route exact strict path="/hospitals/:resourceId/events/:reservationId/booked/">
                    <HospitalConfirm />
                  </Route>
                  <Route exact strict path="/consultants/:gmcCode/">
                    <Consultant />
                  </Route>
                  <Route exact strict path="/consultants/:gmcCode/details/">
                    <ConsultantForm />
                  </Route>
                  <Route exact strict path="/consultants/:gmcCode/booked/">
                    <ConsultantConfirm />
                  </Route>
                  <HealthAssessmentProvider>
                    <Route exact strict path="/healthAssessmentDetails/">
                      <HealthAssessmentDetails />
                    </Route>
                    <Route exact strict path="/healthAssessmentTypes/">
                      <HealthAssessmentTypes />
                    </Route>
                    <Route exact strict path="/healthAssessmentLocations/">
                      <HealthAssessmentLocations />
                    </Route>
                    <Route exact strict path="/healthAssessmentSlots/">
                      <HealthAssessmentSlots />
                    </Route>
                    <Route exact strict path="/healthAssessmentConfirm/">
                      <HealthAssessmentConfirm />
                    </Route>
                    <Route exact strict path="/healthAssessmentConfirmed/">
                      <HealthAssessmentConfirmed />
                    </Route>
                    <Route exact strict path="/health-assessments/appointments/">
                      <HealthAssessmentBookings />
                    </Route>
                  </HealthAssessmentProvider>
                  <Route>
                    <div>404: Not Found</div>
                  </Route>
                </Switch>
              </AuthWrapper>
            </Route>
          </Switch>
        </BrowserRouter>
      </ParentWindowProvider>
    </Provider>
  );
};
