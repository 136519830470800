import React, { useState, useRef, useEffect, useCallback } from 'react';

import LocationFinderCard from './LocationFinderCard';
import CTAButton from './CTAButton';
import GoogleAutocomplete from './GoogleAutocompleteScriptWrapper';

import errorActions from '../actions/errorActions';

import { getPosition, distanceBetween, getLatLng } from '../utils/locationGeoUtils';

import { useHealthAssessmentCtx } from '../contexts';

import './LocationFinder.sass';

const LocationFinder = () => {
  const {
    haCtxSelectors: { locations, locationsWithCoords, locationsOrderedByDistance },
    haCtxActions,
  } = useHealthAssessmentCtx();
  const useCurrentLocationBtn = useRef();
  const viewAllBtn = useRef();
  // TODO: HA24-378: Create state reducer for the whole state
  const [showViewAllBtn, setShowViewAllBtn] = useState(true);
  const [showLocationResults, setShowLocationResults] = useState(false);
  // eslint-disable-next-line prettier/prettier
  const [/* isGeolocationBlocked */, setIsGeolocationBlocked] = useState(false);
  const [selectedCoords, setSelectedCoords] = useState();
  const { log: logError } = errorActions();
  // this is the data from the GET Locations api response
  const locationsData = locations();
  // this is the location data from GET Locations, but lat/lng coords added to each location
  const locationsWithCoordsData = locationsWithCoords();
  // this is the location data ordered by distance from the users input
  const locationsOrderedByDistanceData = locationsOrderedByDistance();

  // Show all location cards when View All is selected
  const handleViewAll = useCallback(() => {
    const locationCards = document.querySelectorAll('.locationFinderCard-container');
    for (let card of locationCards) {
      // Change this to Class usage and state
      card.style.display = 'flex';
    }
    setShowViewAllBtn(false);
  }, []);
  useEffect(() => {
    if (viewAllBtn.current != undefined) {
      // cached incase it is not in the DOM or it has been moved
      const currentViewAllBtn = viewAllBtn.current;
      currentViewAllBtn.addEventListener('click', handleViewAll);
      currentViewAllBtn.addEventListener('enter', handleViewAll);
      return () => {
        currentViewAllBtn.removeEventListener('click', handleViewAll);
        currentViewAllBtn.removeEventListener('enter', handleViewAll);
      };
    }
  }, [showLocationResults]);

  // Calculate the lon/lat for all location from our API
  useEffect(() => {
    const coordinatesError = 'Error fetching coordinates';
    if (locationsData) {
      // Map over the HALocations array and create an array of promises
      const promises = locationsData.map(async obj => {
        const address = obj.address.text;

        // Return a promise for each location
        try {
          const result = await getLatLng(address);
          return {
            ...obj,
            locationCoords: result,
          };
        } catch (error) {
          // Ticket to improve this handling https://nuffielddigital.atlassian.net/browse/HA24-472
          return {
            ...obj,
            locationCoords: coordinatesError,
          };
        }
      });

      // Wait for all promises to resolve using Promise.all
      Promise.all(promises)
        .then(updatedLocationsWithCoords => {
          // TODO: HA24-376: Don't use state here
          haCtxActions.addLocationsWithCoords(updatedLocationsWithCoords);
          const [{ locationCoords }] = Object.values(updatedLocationsWithCoords);
          if (locationCoords === coordinatesError) {
            throw new Error(coordinatesError);
          }
        })
        .catch(() => {
          logError({
            error: 'CUSTOM',
            text: 'Unfortunately, we can’t process your booking online due to an error. Please call us on 0300 123 1844 to complete your booking.',
          });
        });
    }
  }, [locationsData]);

  // Get location via user input field
  const getLocationViaUserInput = addressData => {
    const coords = {
      lat: addressData.geometry.location.lat(),
      lng: addressData.geometry.location.lng(),
    };
    setSelectedCoords(coords);
    setShowLocationResults(true);
  };

  // Get user location via 'use current location'
  // TODO: HA24-381: Geolocation bugs, CMS integration and default state
  const getGeolocationPosition = useCallback(() => {
    setIsGeolocationBlocked(false);
    const successCallback = locationData => {
      setIsGeolocationBlocked(false);
      setSelectedCoords({
        lat: locationData.coords.latitude,
        lng: locationData.coords.longitude,
      });
      setShowLocationResults(true);
      setShowViewAllBtn(true);
    };
    const errorCallback = err => {
      console.warn(`A geolocation error occurred: ${err.code} ${err.message}`);
      // Error code 1 is "permission denied"
      if (err.code === 1) {
        setIsGeolocationBlocked(true);
      }
    };
    getPosition(successCallback, errorCallback);
  }, []);
  useEffect(() => {
    if (useCurrentLocationBtn.current != undefined) {
      const currentUseCurrentLocationBtn = useCurrentLocationBtn.current;
      currentUseCurrentLocationBtn.addEventListener('click', getGeolocationPosition);
      currentUseCurrentLocationBtn.addEventListener('enter', getGeolocationPosition);
      return () => {
        currentUseCurrentLocationBtn.removeEventListener('click', getGeolocationPosition);
        currentUseCurrentLocationBtn.removeEventListener('enter', getGeolocationPosition);
      };
    }
  }, []);

  // Update locations with distance from user location
  useEffect(() => {
    if (selectedCoords) {
      const updatedLocationsWithDistance = locationsWithCoordsData?.map(obj => {
        const calculatedDistance = Math.floor(distanceBetween(selectedCoords, obj.locationCoords));
        return {
          ...obj,
          distance: calculatedDistance,
        };
      });
      haCtxActions.addOrderedLocationsWithDistance(updatedLocationsWithDistance);
    }
  }, [selectedCoords, locationsWithCoordsData]);

  return (
    <div className="location-finder-container">
      <div className="search-container">
        <label className="label" htmlFor="location-finder-input">
          Find your nearest location
        </label>
        <p className="helper-text">Search by postcode, town or area</p>
        <div className="desktop-container">
          <GoogleAutocomplete getResponseData={getLocationViaUserInput} />
          {/* Commented out as de-scoped due to bug on CMS */}
          {/* <p>
            or{" "}
            <CTAButton
              ref={useCurrentLocationBtn}
              className="tertiary-btn"
              action={{
                name: 'use current location',
                type: 'USE_CURRENT_LOCATION',
              }}
            />
            {isGeolocationBlocked &&
              logError({
                error: 'CUSTOM',
                text: 'Unfortunately, location access has been disabled on your browser settings. Please turn them on to use this feature.',
              })}
          </p> */}
        </div>
      </div>

      {showLocationResults && (
        <>
          <div className="results">
            {locationsOrderedByDistanceData?.map(location => {
              const lineOne =
                location.address.line && location.address.line.length > 0
                  ? location.address.line[0]
                  : null;
              const lineTwo =
                location.address.line && location.address.line.length > 1
                  ? location.address.line[1]
                  : null;
              return (
                <LocationFinderCard
                  key={location.identifier.value}
                  locationId={location.identifier.value}
                  locationName={location.address.displayName}
                  locationLineOne={lineOne}
                  locationLineTwo={lineTwo}
                  locationTownCity={location.address.city}
                  locationPostcode={location.address.postalCode}
                  locationText={location.address.text}
                  distance={location.distance}
                />
              );
            })}
          </div>
          {showViewAllBtn && (
            <div className="location-finder-cta-wrapper">
              <CTAButton
                ref={viewAllBtn}
                className="tertiary-btn"
                action={{
                  name: 'View all',
                  type: 'VIEW_ALL',
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LocationFinder;
