import React, { useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import CTAButton from './CTAButton';

import { ReactComponent as Clock } from '../static/img/clock.svg';
import { ReactComponent as People } from '../static/img/people.svg';
import { ReactComponent as ArrowHead } from '../static/img/arrow-alt.svg';

import { useHealthAssessmentCtx } from '../contexts';

import './HealthAssessmentTypeCard.sass';

export default ({
  typeId,
  typeFormattedTitle,
  typeTitle,
  typeDuration,
  typeModules,
  typeDetails,
  typeBookableOnline,
}) => {
  const detailsPanel = useRef();
  const detailsBtn = useRef();
  const detailsBtnText = useRef();
  const history = useHistory();
  const {
    state,
    haCtxSelectors: { haTypeCard },
    haCtxActions,
  } = useHealthAssessmentCtx();
  const { patientId, payorId } = haTypeCard();
  const contactNumber = '03452302040';

  // Handle view/hide toggle
  useEffect(() => {
    if (detailsBtn.current) {
      // eslint-disable-next-line no-inner-declarations
      function toggleHealthAssessmentDetails() {
        detailsPanel.current.classList.toggle('hide');
        detailsBtn.current.classList.toggle('hideDetails');
        // TODO: HA24-377: Better way to handle text change
        detailsBtnText.current.innerText =
          detailsBtnText.current.innerText === 'View details' ? 'Hide details' : 'View details';
      }
      detailsBtn.current.addEventListener('click', toggleHealthAssessmentDetails);
      detailsBtn.current.addEventListener('enter', toggleHealthAssessmentDetails);
      return () => {
        detailsBtn.current.removeEventListener('click', toggleHealthAssessmentDetails);
        detailsBtn.current.removeEventListener('enter', toggleHealthAssessmentDetails);
      };
    }
  }, []);

  // Store data, call API = GET Locations & direct user to next page
  const selectCardAndCallAPI = useCallback(() => {
    // Store selected Health Assessment type to state
    haCtxActions.addType({
      typeId,
      typeTitle,
      typeDuration,
      typeModules,
      typeDetails,
    });
    // Call GET Locations API
    const parameters = {
      patient: patientId,
      payor: payorId,
      appointment_type: typeId,
    };
    const getLocations = async () => await haCtxActions.getLocations(parameters);
    getLocations();
    // Direct user to next page
    history.push('/healthAssessmentLocations/');
  }, []);

  return (
    <div className={`healthAssessmentType ${typeFormattedTitle}`} id={typeId}>
      <div className="mobile-desktop-container">
        <div className="top-container">
          <h3 className="title">{typeTitle}</h3>
          {typeDuration && (
            <p className="time">
              <Clock />
              {typeDuration}
            </p>
          )}
          {typeModules && (
            <p className="modules">
              <People />
              {typeModules}
            </p>
          )}
        </div>
        <div className="bottom-container">
          {typeDetails !== '' && (
            <button
              type="button"
              tabIndex={0}
              className="tertiary-btn m--view_details"
              ref={detailsBtn}
            >
              <p ref={detailsBtnText}>View details</p>
              <ArrowHead />
            </button>
          )}
          {typeBookableOnline ? (
            <CTAButton
              action={{
                call: state ? () => selectCardAndCallAPI : null,
                name: 'Book now',
                type: 'BOOK_NOW',
              }}
              isNotAsync
              onAction={selectCardAndCallAPI}
            />
          ) : (
            <a className="callUsNow-btn" href={`tel:${contactNumber}`}>
              Call us now
            </a>
          )}
        </div>
      </div>
      {!typeBookableOnline && (
        <div className="bookableOnline-false">
          <p>
            Our {typeTitle} can only be booked via phone. Please contact us on{' '}
            <a href={`tel:${contactNumber}`}>03452 302 040</a>. Our lines are open 8am-8pm,
            Monday-Friday
          </p>
        </div>
      )}
      <div className="details hide" ref={detailsPanel}>
        <p>{typeDetails}</p>
      </div>
    </div>
  );
};
