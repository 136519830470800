import { VALIDATORS } from '../enums/validators';
import validatePhoneNumber from './validatePhoneNumber';

export const DETAILS_FORM = {
  // HA24-426 Commented out as not used, keeping for future use
  // date_of_birth: {
  //   type: 'custom_date',
  //   label: 'Date of birth',
  //   required: true,
  //   validator: VALIDATORS.custom,
  //   isValid: date => {
  //     const mDate = moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-DD');
  //     return Boolean(date.day) && Boolean(date.month) && Boolean(date.year) && mDate.isValid();
  //   },
  //   defaultValue: {
  //     day: undefined,
  //     month: undefined,
  //     year: undefined,
  //   },
  // },
  sex_assigned_at_birth: {
    type: 'select',
    label: 'Sex assigned at birth',
    required: true,
    choices: [
      ['male', 'Male'],
      ['female', 'Female'],
    ],
    placeholder: ' ',
    defaultValue: '',
    helperText: 'Please select your sex assigned at birth',
  },
  gender: {
    type: 'select',
    label: 'Gender',
    required: true,
    choices: [
      ['man', 'Man'],
      ['woman', 'Woman'],
      ['nonBinary', 'Non-binary'],
      ['queer', 'Genderqueer/gender non-conforming'],
      ['preferNotToSay', 'Prefer not to say'],
    ],
    placeholder: ' ',
    defaultValue: '',
    helperText: 'Please select your gender',
  },
};

export const SLOTS_FORM = {
  practitioner_gender: {
    type: 'select',
    label: 'Practitioner‘s gender',
    required: false,
    choices: [
      ['female', 'Female'],
      ['male', 'Male'],
      ['unknown', 'No preference'],
    ],
    placeholder: ' ',
    defaultValue: 'unknown',
  },
};

export const CONFIRM_FORM = {
  phone_number: {
    type: 'tel',
    label: 'Phone number',
    required: true,
    validator: VALIDATORS.custom,
    isValid: value => Boolean(value) && validatePhoneNumber(value) && value.indexOf('0') === 0,
    helperText: 'This is just in case we need to speak to you about your booking.',
  },
  communication_preferences: {
    type: 'checkboxgroup',
    defaultValue: {
      email: false,
      sms: false,
      phone: false,
    },
    choices: [
      ['email', 'Email'],
      ['sms', 'SMS'],
      ['phone', 'Phone'],
    ],
  },
};
