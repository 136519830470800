import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import HealthAssessmentHeader from '../components/HealthAssessmentHeader';
import BackBtn from '../components/BackBtn';
import HealthAssessmentTypeCard from '../components/HealthAssessmentTypeCard';
import CommunicationCard from '../components/CommunicationCard';

import { useHealthAssessmentCtx } from '../contexts';

import './HealthAssessmentTypes.sass';

export default () => {
  const history = useHistory();
  const {
    haCtxSelectors: { haTypesPage, isEmptyState },
    state,
  } = useHealthAssessmentCtx();
  const isEmptyStateData = isEmptyState();
  // eligibilityData is from the GET Eligibility api response
  const { loading, eligibilityData, pro4Eligibility } = haTypesPage();

  // Redirect users to start of booking flow if the state is empty
  useEffect(() => {
    const payorId = sessionStorage.getItem('PayorID');
    const activityId = sessionStorage.getItem('ActivityID');
    const idaasId = sessionStorage.getItem('IDaaSID');
    if (isEmptyStateData) {
      history.push(
        `/healthAssessmentDetails/?PayorID=${payorId}&ActivityID=${activityId}&IDaaSID=${idaasId}`,
      );
    }
  }, []);

  return (
    <div className="health-assessment-type-container">
      <HealthAssessmentHeader />
      <BackBtn name="Back to: Enter your details" />

      <h2 className="page-title">Choose your Health Assessment</h2>

      <p className="intro-text">
        Here are the Health Assessments available in your corporate package &ndash; choose the one
        that suits your health and wellbeing needs best.
      </p>
      <p className="intro-text">
        All of our Health Assessments give you access to Trium® &ndash; our online health risk
        assessment &ndash; along with a personalised health dashboard and a bespoke Health
        Assessment report.
      </p>

      <div className="card-container">
        {loading ? (
          <p>loading...</p>
        ) : (
          eligibilityData?.map(
            ({
              ['appointment-type']: typeId,
              description,
              ['total-duration']: typeDuration,
              name,
              ['modules']: modules,
              ['formattedName']: formattedName,
              ['bookable-online']: typeBookableOnline,
            }) => {
              return (
                <HealthAssessmentTypeCard
                  key={typeId}
                  typeDetails={description}
                  typeDuration={typeDuration}
                  typeId={typeId}
                  typeModules={modules}
                  typeTitle={name}
                  typeFormattedTitle={formattedName}
                  typeBookableOnline={typeBookableOnline}
                />
              );
            },
          )
        )}
      </div>

      {!pro4Eligibility && !loading && (
        // TODO: copy should be handled in the component itself
        <CommunicationCard
          type="information"
          title="Speak to us about upgrading"
          copy="To find out if you’re eligible for an upgrade, and what options are available, please call us on <a href='tel:03452302040'>03452 302 040</a> (Monday to Friday 8am to 8pm)."
        />
      )}
    </div>
  );
};
