import React, { useEffect } from 'react';
import './HospitalLocationFilter.sass';

export default ({ hospitals, selectedHospitals, setSelectedHospitals }) => {
  const locations = hospitals.map(hospital => {
    return {
      hospitalId: hospital[0],
      hospitalName: hospital[1].hospitalName,
    };
  });

  const getHospitalIdFromName = hospitalName => {
    switch (hospitalName) {
      case 'Cardiff and Vale Hospitals':
        return [
          hospitals.filter(
            hospital => hospital[1].hospitalName === 'Nuffield Health Cardiff Bay Hospital',
          )[0][0],
          hospitals.filter(
            hospital => hospital[1].hospitalName === 'Nuffield Health Vale Hospital',
          )[0][0],
        ];
      case 'Chester, The Grosvenor Hospital':
        return [
          hospitals.filter(
            hospital => hospital[1].hospitalName === 'Nuffield Health Grosvenor (Chester) Hospital',
          )[0][0],
        ];
      case 'Hereford Hospital':
        return [
          hospitals.filter(
            hospital =>
              hospital[1].hospitalName === 'Nuffield Health Hereford (Wye Valley) Hospital',
          )[0][0],
        ];
      case 'Newcastle upon Tyne Hospital':
        return [
          hospitals.filter(
            hospital => hospital[1].hospitalName === 'Nuffield Health Newcastle-upon-Tyne Hospital',
          )[0][0],
        ];
      case "Nuffield Health at St Bartholomew's Hospital":
        return [
          hospitals.filter(
            hospital => hospital[1].hospitalName === 'Nuffield Health Barts Hospital',
          )[0][0],
        ];
      case 'Oxford, The Manor Hospital':
        return [
          hospitals.filter(
            hospital => hospital[1].hospitalName === 'Nuffield Health Oxford (The Manor) Hospital',
          )[0][0],
        ];
      case 'Tunbridge Wells Hospital':
        return [
          hospitals.filter(
            hospital => hospital[1].hospitalName === 'Nuffield Hospital Tunbridge Wells',
          )[0][0],
        ];
      case 'Warwickshire Hospital':
        return [
          hospitals.filter(
            hospital => hospital[1].hospitalName === 'Nuffield Health Warwick Hospital',
          )[0][0],
        ];
      default:
        if (
          hospitals.filter(hospital => hospital[1].hospitalName.includes(hospitalName)).length > 0
        ) {
          return [
            hospitals.filter(hospital => hospital[1].hospitalName.includes(hospitalName))[0][0],
          ];
        } else {
          let returnAllHospitals = [];
          for (let i = 0; i < hospitals.length; i++) {
            returnAllHospitals.push(hospitals[i][0]);
          }
          return returnAllHospitals;
        }
    }
  };

  let selectedHospitalName;
  const initialiseSelectedHospitals = () => {
    if (selectedHospitalName) {
      setSelectedHospitals(getHospitalIdFromName(selectedHospitalName));
    } else {
      setSelectedHospitals(locations.map(hospital => hospital.hospitalId));
    }
  };

  useEffect(() => {
    const iframeURL = window.location.origin;
    let originURL = 'https://www.nuffieldhealth.com';
    if (iframeURL.includes('-preprod')) {
      originURL = 'https://www.preprod.nuffieldhealth.dev';
    } else if (iframeURL.includes('-uat')) {
      originURL = 'https://www01.uat.nuffieldhealth.dev';
    } else if (iframeURL.includes('-qa')) {
      originURL = 'https://www01.qa.nuffieldhealth.dev';
    }
    window.addEventListener('message', event => {
      if (event.origin === originURL && event.data.event_id === 'selectedHospitalId') {
        selectedHospitalName = event.data.data;
        initialiseSelectedHospitals();
      }
    });
    initialiseSelectedHospitals();
  }, []);

  const handleChange = e => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedHospitals([...selectedHospitals, value]);
    } else {
      setSelectedHospitals(selectedHospitals.filter(val => val !== value));
    }
  };

  const isSelected = hospitalId => {
    if (selectedHospitals.includes(hospitalId)) {
      return 'checked';
    }
    return '';
  };

  return (
    <div className="location-filter__wrapper">
      <div className="location__label">Location</div>
      {locations && locations.length === 1 && (
        <div className="location__name">{locations[0].hospitalName}</div>
      )}
      {locations && locations.length > 1 && (
        <ul>
          {locations.map(location => {
            return (
              <li key={location.hospitalId}>
                <label htmlFor={location.hospitalId}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="languages"
                    value={location.hospitalId}
                    id={location.hospitalId}
                    onChange={handleChange}
                    checked={isSelected(location.hospitalId)}
                  />
                  {location.hospitalName}
                </label>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
