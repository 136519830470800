import React, { useCallback } from 'react';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';

import GoogleAutocompleteSuggestion from './GoogleAutocompleteSuggestion';
import errorActions from '../actions/errorActions';
import { useHealthAssessmentCtx } from '../contexts';

import './GoogleAutocomplete.sass';

export default ({ getResponseData }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'gb' },
      types: ['geocode'],
    },
    debounce: 300,
  });
  const { state } = useHealthAssessmentCtx();
  const { log: logError } = errorActions();
  const handleSelect = useCallback(({ description }) => {
    return () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description })
        .then(results => {
          if (getResponseData) {
            getResponseData(results[0]);
          }
        })
        .catch(() => {
          logError({
            error: 'CUSTOM',
            text: 'Unfortunately, we can’t process your booking online due to an error. Please call us on 0300 123 1844 to complete your booking.',
          });
        });
    };
  }, []);

  return (
    <div className="google-location-finder">
      <input
        id="location-finder-input"
        name="location-finder-input"
        value={value}
        onChange={e => setValue(e.target.value)}
        disabled={!ready && state?.loading}
        autoComplete="off"
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === 'OK' && (
        <div className="google-location-finder-results">
          <ul className="placeList">
            {data.map((suggestion, key) => (
              <GoogleAutocompleteSuggestion
                key={key}
                suggestion={suggestion}
                onClick={handleSelect(suggestion)}
              />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
